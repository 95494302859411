<template>
    <section>
        <v-row>
            <v-col
            cols="6"
            >
                <h2 class="headline">Mis estudiantes</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col
            class="d-flex
            flex-wrap
            justify-center"
            >
                <v-card
                width="500"
                color="blue accent-4"
                elevation="6"
                dark
                class="mx-2 my-2 rounded-lg"
                v-for="child in children"
                :key="child.id"
                >
                    <v-card-title>
                        {{child.fullname}} <br>
                    </v-card-title>

                    <v-card-subtitle>
                        <b>Carnet</b>: {{child.carnet}} <br>
                        <b>Sede:</b> {{child.actual_enrollment.sede.nombre_sede}} <br>
                        <b>Jornada:</b> {{child.actual_enrollment.jornada.nombre_jornada}} <br>
                        <b>Nivel:</b> {{child.actual_enrollment.nivel.nombre_nivel}} <br>
                        <b>Grado:</b> {{child.actual_enrollment.grado.nombre}}
                    </v-card-subtitle>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                exact :to="{ name: 'ActivitiesByStudent', params: { userid: child.id } }"
                                >
                                    <v-icon>mdi-text-box-multiple-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Actividades</span>
                        </v-tooltip>

                        <v-tooltip bottom color="warning" v-if="child.authorized">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                exact :to="{ name: 'StudentGradeCard', params: { userid: child.id } }"
                                >
                                    <v-icon>mdi-book-open-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Calificaciones</span>
                        </v-tooltip>

                        <v-tooltip bottom color="success">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                exact :to="{ name: 'Attendance', params: { userid: child.id } }"
                                >
                                    <v-icon>mdi-clipboard-text-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Asistencia</span>
                        </v-tooltip>

                        <v-tooltip bottom color="info">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                exact :to="{ name: 'StudentCalendar', params: { userid: child.id } }"
                                >
                                    <v-icon>mdi-calendar-month</v-icon>
                                </v-btn>
                            </template>
                            <span>Calendario</span>
                        </v-tooltip>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </section>
</template>

<script>
    import {mapState,mapMutations} from "vuex"

    export default {
        data() {
            return {
                show: false,
                children: []
            }
        },
        computed: {
            ...mapState(['loading','user']),
        },
        methods: {
            ...mapMutations(['setLayout','setLoading','setNotify']),
            getChildren() {
                this.setLoading(true)
                let user = JSON.parse(this.user)

                this.axios.post('/getchildren',{
                    user_id: user.id
                })
                .then((response) => {
					this.setLoading(false)
                    this.children = response.data.children
					console.log(response)
				})
				.catch((error) => {
					this.setLoading(false)

					console.log(error)
				})
            }
        },
        created(){
            this.setLayout('parent-layout')
        },
        mounted() {
            this.getChildren()
        }
    }
</script>
