<template>
    <section></section>
</template>

<script>
    import {mapMutations} from "vuex"

    export default {
        methods: {
            ...mapMutations(['setLayout']),
        },
        created(){
            this.setLayout('main-layout')
        }
    }
</script>
