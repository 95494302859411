var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('h2',{staticClass:"headline"},[_vm._v("Mis estudiantes")])])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex\n        flex-wrap\n        justify-center"},_vm._l((_vm.children),function(child){return _c('v-card',{key:child.id,staticClass:"mx-2 my-2 rounded-lg",attrs:{"width":"500","color":"blue accent-4","elevation":"6","dark":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(child.fullname)+" "),_c('br')]),_c('v-card-subtitle',[_c('b',[_vm._v("Carnet")]),_vm._v(": "+_vm._s(child.carnet)+" "),_c('br'),_c('b',[_vm._v("Sede:")]),_vm._v(" "+_vm._s(child.actual_enrollment.sede.nombre_sede)+" "),_c('br'),_c('b',[_vm._v("Jornada:")]),_vm._v(" "+_vm._s(child.actual_enrollment.jornada.nombre_jornada)+" "),_c('br'),_c('b',[_vm._v("Nivel:")]),_vm._v(" "+_vm._s(child.actual_enrollment.nivel.nombre_nivel)+" "),_c('br'),_c('b',[_vm._v("Grado:")]),_vm._v(" "+_vm._s(child.actual_enrollment.grado.nombre)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","exact":"","to":{ name: 'ActivitiesByStudent', params: { userid: child.id } }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-text-box-multiple-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Actividades")])]),(child.authorized)?_c('v-tooltip',{attrs:{"bottom":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","exact":"","to":{ name: 'StudentGradeCard', params: { userid: child.id } }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-book-open-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Calificaciones")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","exact":"","to":{ name: 'Attendance', params: { userid: child.id } }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clipboard-text-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Asistencia")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","exact":"","to":{ name: 'StudentCalendar', params: { userid: child.id } }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-month")])],1)]}}],null,true)},[_c('span',[_vm._v("Calendario")])])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }